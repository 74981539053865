<template>
  <div>
    <h1>Create Announcement</h1>
    <AnnouncementForm/>
  </div>
</template>

<script>
import AnnouncementForm from "@/views/SuperUserViews/Announcements/AnnouncementForm.vue";

export default {
  name: "AnnouncementCreate",
  components: {AnnouncementForm}
}
</script>

<style scoped>

</style>