<template>
  <div>
    <form @submit.prevent="submitButtonClicked">
      <div class="row">
        <div class="col-sm">
          <label>Title</label>
          <input class="form-control" type="text" v-model="announcement.announcementTitle" style="height: 37px"/>
        </div>
        <div class="col-sm">
          <label>Active Begin Date
            <b-tooltip target="_self" title="The first day this announcement will appear to users"><i
                class="bi-info-circle"></i></b-tooltip>
          </label>
          <DatePicker v-model="announcement.activeStartDate" :max-date="maxEndDate" autoApply
                      :enable-time-picker="false" textInput uid="activeStartDate"/>
        </div>
        <div class="col-sm">
          <label>Expiration Date
            <b-tooltip target="_self" title="The last day this announcement will appear to users"><i
                class="bi-info-circle"></i></b-tooltip>
          </label>
          <DatePicker v-model="announcement.expirationDate" :max-date="maxEndDate" :min-date="minExpirationDate"
                      autoApply :enable-time-picker="false" textInput uid="expirationDate"/>

        </div>
      </div>
      <div>
        <label>Announcement Details</label>
        <div style="text-align: left;">
          <QuillEditor
              v-model:content="announcement.announcementText"
              content-type="html"
              theme="snow"
              toolbar="essential"
              style="min-height: 300px; max-height: 300px; overflow-y: scroll;"
              contenteditable="true"
          />
        </div>
      </div>
      <div class="col-sm-3 offset-9">
        <button class="button btn-primary" type="submit">{{ editingExisting ? `Update` : `Create` }} Announcement
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {ref, onBeforeMount, watch, computed} from "vue";
import axios from "axios";
import store from "@/store";
import {createAnnouncement, editAnnouncement, getAnnouncementByUuid} from "@/Helpers/ApiCalls/AnnouncementAPICalls";
import Swal from "sweetalert2";
import {QuillEditor} from "@vueup/vue-quill";
import {addDaysToDate, subtractDaysFromDate} from "@/Helpers/DateHelper";
import DatePicker from "@vuepic/vue-datepicker";
import router from "@/router";

export default {
  name: "AnnouncementForm",
  components: {QuillEditor, DatePicker},
  props: {
    announcementUuid: {
      type: String,
      nullable: true
    },
    editingExisting: Boolean
  },
  setup(props) {
    const announcement = ref({
      announcementTitle: "",
      announcementText: "",
      activeStartDate: new Date(),
      expirationDate: addDaysToDate(7, new Date())
    });
    const authToken = store.getters['auth/authenticated'];

    const maxEndDate = ref(addDaysToDate(Math.round(365 / 2)));

    onBeforeMount(() => {
      if (props.editingExisting &&
          props.announcementUuid !== undefined
          && props.announcementUuid !== null &&
          props.announcementUuid !== "") {
        getAnnouncementOnEdit();
      }
    })

    function submitButtonClicked() {
      if (props.editingExisting) {
        submitEdit();
      } else {
        submitCreate();
      }
    }

    async function getAnnouncementOnEdit() {
      await axios.get(getAnnouncementByUuid(props.announcementUuid), {
        headers: {
          "Authorization": `Bearer ${authToken.value}`
        }
      }).then((result) => {
        announcement.value = result.data;
        announcement.value.activeStartDate = new Date(result.data.activeStartDate);
        announcement.value.expirationDate = new Date(result.data.expirationDate);
      })
    }

    async function submitCreate() {
      await axios.post(createAnnouncement(), announcement.value, {
        headers: {
          "Authorization": `Bearer ${authToken.value}`
        }
      }).then(() => {
        Swal.fire({
          title: 'Created Announcement',
          confirmButtonText: 'Okay'
        }).then(() => {
          router.push({name: 'AnnouncementDashboard'})
        })
      })
    }

    async function submitEdit() {
      await axios.put(editAnnouncement(props.announcementUuid), announcement.value, {
        headers: {
          "Authorization": `Bearer ${authToken.value}`
        }
      }).then(() => {
        Swal.fire({
          title: 'Updated Announcement',
          confirmButtonText: 'Okay'
        }).then(() => {
          router.push({name: 'AnnouncementDashboard'});
        })
      })
    }

    const minExpirationDate = computed(() => {
      let base_date = new Date(announcement.value.activeStartDate);
      return addDaysToDate(1, base_date);
    })

    return {
      announcement,
      maxEndDate,
      minExpirationDate,
      submitButtonClicked
    }
  }
}
</script>

<style scoped>

</style>